import React from "react";
import Container from "@layout/container";
import { Link } from "gatsby";

function PageNotFound() {
  return (
    <Container>
      <div className="hn-container">
        <div className="NotFoundWrapper">
          <h1>ページが見つかりません</h1>
          <p>
            指定されたURLのページは存在しません。 <br/>
            サイト更新などによってURLが変更になったか、URLが正しく入力されていない可能性があります。
          </p>
          <Link to="/">ホームページに戻る</Link>
        </div>
      </div>
    </Container>
  );
}

export default PageNotFound;
